import React from "react"
import { Error, Layout, Seo } from "@components"

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        {...{
          description: "Hmm - that page doesn't exist",
          meta: "Hmm - that page doesn't exist",
          title: "Habitat Planning",
        }}
      />
      <Error message="Hmm - that page doesn't exist" />
    </Layout>
  )
}

export default NotFoundPage
